/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css"
import 'jquery/dist/jquery.min.js'
// import "popper.js/dist/popper.min"
import 'popper.js/dist/popper.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import CustomLayout from "./wrapPageElement"

export const wrapPageElement = CustomLayout
